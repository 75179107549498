import {
  all,
  call,
  fork,
  select,
  put,
  takeEvery,
  SagaReturnType,
} from 'redux-saga/effects';

import {
  FETCH_ACCOUNT_LISTING,
  REFETCH_ACCOUNT_LISTING,
  FETCH_COMPANY_NAMES,
} from '../actions';

import {
  fetchAccountListing,
  fetchAccountListingSuccess,
  fetchAccountListingError,
  fetchCompanyNamesSuccess,
  fetchCompanyNamesError,
} from './actions';

import { fetchAccountsAPI, fetchCompanyNamesAPI } from '../../apiCalls';

import { RootState } from '../reducers';
import {
  AccountFilterState,
  AccountListingResponse,
  CompanyNamesResponse,
} from './model';
import messageStrings from '../../helpers/messageStrings';

const fetchAccountsAsync = async (
  filters: AccountFilterState
): Promise<AccountListingResponse> => {
  try {
    const response = await fetchAccountsAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchAccounts({
  payload,
}: {
  type: typeof FETCH_ACCOUNT_LISTING;
  payload: AccountFilterState;
}) {
  try {
    const results: SagaReturnType<typeof fetchAccountsAsync> = yield call(
      fetchAccountsAsync,
      payload
    );

      yield put(fetchAccountListingSuccess(results));

  } catch (error) {
    yield put(fetchAccountListingError(error.message || messageStrings.defaultError));
  }
}
export function* watchFetchAccountListing() {
  yield takeEvery(FETCH_ACCOUNT_LISTING, handleFetchAccounts);
}

function* handleRefetchAccounts() {
  const listingFilters: AccountFilterState = yield select(
    (state: RootState) => state.accountFilter
  );
  yield put(fetchAccountListing(listingFilters));
}

export function* watchRefetchAccountListing() {
  yield takeEvery(REFETCH_ACCOUNT_LISTING, handleRefetchAccounts);
}

const fetchCompanyNamesAsync = async (): Promise<CompanyNamesResponse> => {
  try {
    const response = await fetchCompanyNamesAPI();
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchCompanyNames(action: {
  type: typeof FETCH_COMPANY_NAMES;
}) {
  try {
    const responseData: SagaReturnType<typeof fetchCompanyNamesAsync> =
      yield call(fetchCompanyNamesAsync);
    if (responseData.data) {
      yield put(fetchCompanyNamesSuccess(responseData.data));
    } else {
      yield put(fetchCompanyNamesError(messageStrings.noCompanyNames));
    }
  } catch (error) {
    yield put(fetchCompanyNamesError(error.message || messageStrings.noCompanyNames));
  }
}

export function* watchFetchCompanyNames() {
  yield takeEvery(FETCH_COMPANY_NAMES, handleFetchCompanyNames);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchAccountListing),
    fork(watchRefetchAccountListing),
    fork(watchFetchCompanyNames),
  ]);
}
