import axios from 'axios';
import { store } from './index';
import { enableForceLogout } from './redux/auth/actions';
import { TransactionFilterState } from './redux/transaction/model';
import {
  BeneficiaryFilterState,
  EditSelectedBalancesPayload,
  EditAllBalancesPayload,
} from './redux/beneficiary/model';
import { DistributionFilterState } from './redux/distribution/model';
import { DistributionId } from './redux/massDistribution/model';
import { AccountFilterState } from './redux/account/model';
import { CreateAccountParams } from './views/app/CreateAccount/CreateAccount';
import { EditAccountParams } from './views/app/EditAccount/EditAccount';
import { unauthorizedRoute } from './helpers/navLinks';
import { SystemConfigParams } from './views/app/SystemConfig/SystemConfig';
import { ENV, IS_ENV_PRODUCTION } from './helpers/defaultValues';

import messageStrings from './helpers/messageStrings'

const BASE_URL = {
  stage: 'https://ambackend-staging.a5tec.com.sg',
  prod: 'https://am-backend.a5tec.com.sg',
};

//Add different base url to requests if in production environment
export const axiosInstance = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, 'Content-Type': 'application/json' },
  // @ts-ignore
  baseURL: IS_ENV_PRODUCTION ? BASE_URL[ENV] : '',
});

const INVALID_JWT_TOKEN_STATUS_CODES = [403];
const LOGOUT_URL = '/authentication/logoff';

// Listen for invalid JWT token on every bad response and log user out
axiosInstance.interceptors.response.use(
  function (response) {
    // Healthy status code
    return response;
  },
  function (error) {
    //Network error, no response, no status code
    if (!error.response) {
      return Promise.reject({message: messageStrings.networkError});
    }

    // Unhealthy status code
    const errorStatusCode = error.response.status;

    //Invalid JWT status code
    if (INVALID_JWT_TOKEN_STATUS_CODES.includes(errorStatusCode)) {
      // API error was due to invalid jwt token, force logout
      // No access to router's history here
      // have to go through redux -> ForceLogout component (pick up history here)-> logout action
      const configURL = error.response.config.url;
      const { responseURL } = error.response.request;
      // Dispatch logout once, else infinite loop
      if (
        !configURL.includes(LOGOUT_URL) &&
        !responseURL.includes(LOGOUT_URL)
      ) {
        store.dispatch(enableForceLogout());
      }
    } else {
      //Other unhealthy status codes
      return Promise.reject(error.response.data);
    }
  }
);

export const loginAPI = (email: string, password: string) => {
  return axiosInstance.post('/authentication/authenticate', {
    email,
    password,
    isWeb: true,
  });
};

export const fetchUserDetailsAPI = () => {
  return axiosInstance.get('/authentication/user-details');
};

export const forgotPasswordAPI = (email: string) => {
  return axiosInstance.post('/authentication/forgot-password', {
    email,
    isWeb: true,
  });
};

export const resetPasswordAPI = (newPassword: string, resetToken: string) => {
  return axiosInstance.post('/authentication/reset-password', {
    password: newPassword,
    resetToken,
  });
};

export const changePasswordAPI = (oldPassword: string, newPassword: string) => {
  return axiosInstance.post('/authentication/change-password', {
    oldPassword,
    password: newPassword,
  });
};

export const logoutAPI = () => {
  return axiosInstance.post(LOGOUT_URL);
};

export const fetchDashboardDataAnalyticsAPI = () => {
  return axiosInstance.get('/transaction/data-analytics');
};

export const fetchTransactionsAPI = (filters: TransactionFilterState) => {
  return axiosInstance.post('/transaction/transactions-all', filters);
};

export const fetchTransactionsExportAPI = (filters: TransactionFilterState) => {
  return axiosInstance.post('/transaction/transactions-all', {
    ...filters,
    isExport: true,
  });
};

export const fetchBeneficiariesAPI = (filters: BeneficiaryFilterState) => {
  return axiosInstance.post('/account/beneficiaries-all', filters);
};

export const fetchBeneficiaryCountAPI = () => {
  return axiosInstance.get('/account/beneficiary-count');
};

export const editSelectedBalancesAPI = (
  request: EditSelectedBalancesPayload
) => {
  return axiosInstance.put('/account/edit-selected-balances', request);
};

export const editAllBalancesAPI = (request: EditAllBalancesPayload) => {
  return axiosInstance.put('/account/edit-all-balances', request);
};

export const fetchDistributionsAPI = (filters: DistributionFilterState) => {
  return axiosInstance.post('/transaction/distributions-all', filters);
};

export const fetchMassDistributionAPI = (distributionId: DistributionId) => {
  return axiosInstance.post(`/transaction/distributions/${distributionId}`);
};

export const fetchAccountsAPI = (filters: AccountFilterState) => {
  return axiosInstance.post('/account/accounts-by-role', filters);
};

export const fetchCompanyNamesAPI = () => {
  return axiosInstance.get('/parent-organization/parent-organizations-all');
};

export const createAccountAPI = (requestParams: CreateAccountParams) => {
  return axiosInstance.post('/authentication/register', requestParams);
};

export const fetchAccountWithIdAPI = (accountId: number) => {
  return axiosInstance.post('/account/account-by-id', { id: accountId });
};
export const editAccountAPI = (requestParams: EditAccountParams) => {
  return axiosInstance.put('/authentication/edit-user', requestParams);
};

export const fetchAccountWithEmailAPI = (email: string) => {
  return axiosInstance.post('/account/account-by-email', { email });
};

export const fetchParentOrganizationWithNameAPI = (companyName: string) => {
  return axiosInstance.post(
    '/parent-organization/parent-organization-by-name',
    {
      name: companyName,
    }
  );
};
export const editParentOrganizationAPI = (
  requestParams: SystemConfigParams
) => {
  return axiosInstance.put(
    '/parent-organization/edit-parent-organization',
    requestParams
  );
};
