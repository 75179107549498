import { SET_SIDEBAR_OPEN } from '../actions';
import { SidebarState, SidebarAction } from './model';
import { getIsMobile } from '../../helpers/utils';

const INIT_STATE:SidebarState = {
  isSidebarOpen: getIsMobile() ? false : true,
};

export default (state: SidebarState = INIT_STATE, action: SidebarAction) => {
  switch (action.type) {
    case SET_SIDEBAR_OPEN:
      return { ...state, isSidebarOpen: action.payload };
    default:
      return state;
  }
};
