import {
  SET_BENEFICIARY_FILTER,
  FETCH_BENEFICIARY_LISTING,
  FETCH_BENEFICIARY_LISTING_SUCCESS,
  FETCH_BENEFICIARY_LISTING_ERROR,
  UPDATE_BENEFICIARY_CHECKBOXES,
  FETCH_BENEFICIARY_COUNT,
  FETCH_BENEFICIARY_COUNT_SUCCESS,
  FETCH_BENEFICIARY_COUNT_ERROR,
  EDIT_SELECTED_BALANCES,
  EDIT_SELECTED_BALANCES_SUCCESS,
  EDIT_SELECTED_BALANCES_ERROR,
  EDIT_ALL_BALANCES,
  EDIT_ALL_BALANCES_SUCCESS,
  EDIT_ALL_BALANCES_ERROR,
  CLEAR_EDIT_BALANCE_EFFECTS,
} from '../actions';

import {
  BeneficiaryFilterState,
  BeneficiaryState,
  BeneficiaryFilterAction,
  BeneficiaryAction,
  BeneficiaryCheckboxesState,
  BeneficiaryCheckboxesAction,
  BeneficiaryCheckbox,
  BeneficiaryCountState,
  BeneficiaryCountAction,
  BeneficiaryItem,
  EditBalanceState,
  EditBalanceAction,
} from './model';

const INIT_BENEFICIARY_FILTER_STATE:BeneficiaryFilterState = {
  currentPage: 1,
  query: '',
  minBalance: '0',
  maxBalance: '1000',
  sortBy: 'beneficiaryName',
  sortOrder: 'ASC',
};

export const beneficiaryFilterReducer = (
  state: BeneficiaryFilterState = INIT_BENEFICIARY_FILTER_STATE,
  action: BeneficiaryFilterAction
) => {
  switch (action.type) {
    case SET_BENEFICIARY_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_BENEFICIARY_STATE:BeneficiaryState = {
  results: null,
  loading: false,
  error: '',
};

export const beneficiaryReducer = (
  state: BeneficiaryState = INIT_BENEFICIARY_STATE,
  action: BeneficiaryAction
) => {
  switch (action.type) {
    case FETCH_BENEFICIARY_LISTING:
      return { ...state, loading: true, error: '' };
    case FETCH_BENEFICIARY_LISTING_SUCCESS:
      return { loading: false, error: '', results: { ...action.payload } };
    case FETCH_BENEFICIARY_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const CHECKBOX_FIELD_NAME_ID_PREPEND = 'beneficiary-listing-id-';
const INIT_BENEFICIARY_CHECKBOXES_STATE:BeneficiaryCheckboxesState = null;

export const beneficiaryCheckboxesReducer = (
  state: BeneficiaryCheckboxesState = INIT_BENEFICIARY_CHECKBOXES_STATE,
  action: BeneficiaryCheckboxesAction | BeneficiaryAction
) => {
  switch (action.type) {
    case FETCH_BENEFICIARY_LISTING:
      return null;
    case FETCH_BENEFICIARY_LISTING_ERROR:
      return null;
    case FETCH_BENEFICIARY_LISTING_SUCCESS:
      if (!action.payload || !('items' in action.payload)) {
        return state;
      }
      return action.payload.items.map((beneficiary: BeneficiaryItem) => ({
        id: beneficiary.id,
        fieldName: CHECKBOX_FIELD_NAME_ID_PREPEND + beneficiary.id,
        isChecked: false,
      }));
    case UPDATE_BENEFICIARY_CHECKBOXES:
      if (!Array.isArray(action.payload)) {
        return state;
      }
      return [
        ...action.payload.map((checkbox: BeneficiaryCheckbox) => ({
          ...checkbox,
        })),
      ];
    default:
      return state;
  }
};

const INIT_BENEFICIARY_COUNT_STATE:BeneficiaryCountState = null;

export const beneficiaryCountReducer = (
  state: BeneficiaryCountState = INIT_BENEFICIARY_COUNT_STATE,
  action: BeneficiaryCountAction
) => {
  switch (action.type) {
    case FETCH_BENEFICIARY_COUNT:
      return null;
    case FETCH_BENEFICIARY_COUNT_SUCCESS:
      return action.payload;
    case FETCH_BENEFICIARY_COUNT_ERROR:
      return null;
    default:
      return state;
  }
};

const INIT_EDIT_BALANCE_STATE:EditBalanceState = {
  showEffects: false,
  loading: false,
  error: '',
};

export const editBalanceReducer = (
  state: EditBalanceState = INIT_EDIT_BALANCE_STATE,
  action: EditBalanceAction
) => {
  switch (action.type) {
    case EDIT_SELECTED_BALANCES:
      return { showEffects: true, loading: true, error: '' };
    case EDIT_SELECTED_BALANCES_SUCCESS:
      return { showEffects: true, loading: false, error: '' };
    case EDIT_SELECTED_BALANCES_ERROR:
      return { showEffects: true, loading: false, error: action.payload };
    case EDIT_ALL_BALANCES:
      return { showEffects: true, loading: true, error: '' };
    case EDIT_ALL_BALANCES_SUCCESS:
      return { showEffects: true, loading: false, error: '' };
    case EDIT_ALL_BALANCES_ERROR:
      return { showEffects: true, loading: false, error: action.payload };
    case CLEAR_EDIT_BALANCE_EFFECTS:
      return { showEffects: false, loading: false, error: '' };
    default:
      return state;
  }
};
