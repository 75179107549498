import moment from 'moment';
import {
  JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY,
  deviceBreakpoints,
  emailValidationRegexp,
} from './defaultValues';

import messageStrings from './messageStrings';
import { validatePasswordRequirements } from '../components/PasswordChecker/PasswordChecker';

export const mapOrder = (
  array: { [key: string]: any }[],
  order: string[],
  key: string
) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const setTokenExpirationDateInLocalStorage = (
  jwtExpirationDate: Date | null
) => {
  try {
    if (jwtExpirationDate) {
      localStorage.setItem(
        JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY,
        moment(jwtExpirationDate).format('DD/MM/YYYY HH:mm:ss')
      );
    } else {
      localStorage.removeItem(JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setTokenExpirationDateInLocalStorage -> error',
      error
    );
  }
};
export const getTokenExpirationDateFromLocalStorage = () => {
  let jwtExpirationDate = null;

  try {
    jwtExpirationDate =
      localStorage.getItem(JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY) || '';
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getTokenExpirationDateFromLocalStorage -> error',
      error
    );
  }
  return jwtExpirationDate;
};

export const hasValidJWTToken = () => {
  const jwtExpirationDate = getTokenExpirationDateFromLocalStorage();
  let isExpired = false;
  let error = '';

  if (jwtExpirationDate) {
    isExpired = moment().isAfter(
      moment(jwtExpirationDate, 'DD/MM/YYYY HH:mm:ss')
    );

    if (isExpired) error = messageStrings.relogin;
  } else {
    error = messageStrings.login;
  }

  return {
    isJWTTokenValid: jwtExpirationDate && !isExpired,
    jwtTokenError: error,
  };
};

export const getBrowserWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const getIsMobile = () => {
  return getBrowserWidth() < deviceBreakpoints.desktop;
};

export const formatToCurrency = (
  num: number,
  forceCents: boolean = true,
  hasDollarSign: boolean = true
) => {
  let processedNum = num;

  if (forceCents) {
    processedNum = Math.round(num * 100) / 100;
  }
  // 1900.30 -> 1,900.30
  return `${hasDollarSign ? '$' : ''}${processedNum.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: forceCents ? 2 : 0,
  })}`;
};

export const limitCents = (valStr: string) => {
  let truncatedVal = valStr;
  const dotIndex = truncatedVal.indexOf('.');
  if (dotIndex > -1 && truncatedVal.length > dotIndex + 3) {
    truncatedVal = truncatedVal.substring(0, dotIndex + 3);
  }

  return truncatedVal;
};

export const formatDateTime = (dateTimeString: string | Date) => {
  return moment(dateTimeString).format('DD/MM/YYYY hh:mmA');
};

export const getFullNameFromAccount = (account: {
  user?: { firstName?: string; lastName?: string };
}): string => {
  if (!account.user) {
    return '';
  }
  const { firstName = '', lastName = '' } = account.user;
  return firstName + (lastName ? ` ${lastName}` : '');
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }

  return str.substring(0, maxLength).concat('...');
};

export const capitalizeFirstChar = (string: string) => {
  const words = string.split(' ');

  return words
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
};

export const validateEmail = (value: string) => {
  const email = value.replace(/\s/g, '');
  let error;

  if (!email) {
    error = messageStrings.requiredEmail;
  } else if (!emailValidationRegexp.test(email)) {
    error = messageStrings.invalidEmail;
  }
  return error;
};

export const validateOptionalDate = (value: string) => {
  let error;
  if (value && !moment(value, 'DD/MM/YYYY', true).isValid()) {
    error = messageStrings.invalidDateFormat;
  }
  return error;
};

export const validateRequired = (value: string) => {
  let error;

  if (!value) {
    error = messageStrings.requiredInput;
  }
  return error;
};

export const validateRequiredOption = (value: string) => {
  let error;
  if (!value) {
    error = messageStrings.requiredOption;
  }
  return error;
};

export const validateNewAndConfirmPasswords = (values: {
  newPassword: string;
  confirmPassword: string;
}) => {
  const { newPassword, confirmPassword } = values;
  const errors: { confirmPassword?: string; newPassword?: string } = {};
  const results = validatePasswordRequirements(newPassword);

  if (!results.hasPassedAll) {
    errors.newPassword = messageStrings.newPasswordFail;
  }

  if (confirmPassword && newPassword && newPassword !== confirmPassword) {
    errors.confirmPassword = messageStrings.confirmNotMatchNewPassword;
  } else if (!confirmPassword) {
    errors.confirmPassword = messageStrings.noConfirmPassword;
  }
  return errors;
};

export const getDayOfMonthOptions = () => {
  return [
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: '6', text: '6' },
    { value: '7', text: '7' },
    { value: '8', text: '8' },
    { value: '9', text: '9' },
    { value: '10', text: '10' },
    { value: '11', text: '11' },
    { value: '12', text: '12' },
    { value: '13', text: '13' },
    { value: '14', text: '14' },
    { value: '15', text: '15' },
    { value: '16', text: '16' },
    { value: '17', text: '17' },
    { value: '18', text: '18' },
    { value: '19', text: '19' },
    { value: '20', text: '20' },
    { value: '21', text: '21' },
    { value: '22', text: '22' },
    { value: '23', text: '23' },
    { value: '24', text: '24' },
    { value: '25', text: '25' },
    { value: '26', text: '26' },
    { value: '27', text: '27' },
    { value: 'last', text: 'Last day' },
  ];
};
export const getHourOptions = () => {
  return [
    { value: '00', text: '12 AM' },
    { value: '01', text: '1 AM' },
    { value: '02', text: '2 AM' },
    { value: '03', text: '3 AM' },
    { value: '04', text: '4 AM' },
    { value: '05', text: '5 AM' },
    { value: '06', text: '6 AM' },
    { value: '07', text: '7 AM' },
    { value: '08', text: '8 AM' },
    { value: '09', text: '9 AM' },
    { value: '10', text: '10 AM' },
    { value: '11', text: '11 AM' },
    { value: '12', text: '12 PM' },
    { value: '13', text: '1 PM' },
    { value: '14', text: '2 PM' },
    { value: '15', text: '3 PM' },
    { value: '16', text: '4 PM' },
    { value: '17', text: '5 PM' },
    { value: '18', text: '6 PM' },
    { value: '19', text: '7 PM' },
    { value: '20', text: '8 PM' },
    { value: '21', text: '9 PM' },
    { value: '22', text: '10 PM' },
    { value: '23', text: '11 PM' },
  ];
};
