import {
  BeneficiaryFilter,
  BeneficiaryListing,
  BeneficiaryCountState,
} from './model';

import {
  SET_BENEFICIARY_FILTER,
  FETCH_BENEFICIARY_LISTING,
  FETCH_BENEFICIARY_LISTING_SUCCESS,
  FETCH_BENEFICIARY_LISTING_ERROR,
  UPDATE_BENEFICIARY_CHECKBOXES,
  FETCH_BENEFICIARY_COUNT,
  FETCH_BENEFICIARY_COUNT_SUCCESS,
  FETCH_BENEFICIARY_COUNT_ERROR,
  EDIT_SELECTED_BALANCES,
  EDIT_SELECTED_BALANCES_SUCCESS,
  EDIT_SELECTED_BALANCES_ERROR,
  EDIT_ALL_BALANCES,
  EDIT_ALL_BALANCES_SUCCESS,
  EDIT_ALL_BALANCES_ERROR,
  CLEAR_EDIT_BALANCE_EFFECTS,
} from '../actions';

export const setBeneficiaryFilter = (
  newFiltersKeyValues: BeneficiaryFilter
) => {
  return { type: SET_BENEFICIARY_FILTER, payload: newFiltersKeyValues };
};

export const fetchBeneficiaryListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_BENEFICIARY_LISTING, payload: filters };
};

export const fetchBeneficiaryListingSuccess = (results: BeneficiaryListing) => {
  return { type: FETCH_BENEFICIARY_LISTING_SUCCESS, payload: results };
};

export const fetchBeneficiaryListingError = (message: string) => {
  return { type: FETCH_BENEFICIARY_LISTING_ERROR, payload: message };
};

export const updateBeneficiaryCheckboxes = (
  checkboxes: {
    id: number;
    fieldName: string;
    isChecked: boolean;
  }[]
) => {
  return { type: UPDATE_BENEFICIARY_CHECKBOXES, payload: checkboxes };
};

export const fetchBeneficiaryCount = () => {
  return { type: FETCH_BENEFICIARY_COUNT };
};

export const fetchBeneficiaryCountSuccess = (
  beneficiaryCount: BeneficiaryCountState
) => {
  return { type: FETCH_BENEFICIARY_COUNT_SUCCESS, payload: beneficiaryCount };
};

export const fetchBeneficiaryCountError = () => {
  return { type: FETCH_BENEFICIARY_COUNT_ERROR };
};

export const editSelectedBalances = (
  targetBalance: number,
  selectedIds: number[]
) => {
  return {
    type: EDIT_SELECTED_BALANCES,
    payload: { targetBalance, selectedIds },
  };
};

export const editSelectedBalancesSuccess = () => {
  return { type: EDIT_SELECTED_BALANCES_SUCCESS };
};

export const editSelectedBalancesError = (message: string) => {
  return { type: EDIT_SELECTED_BALANCES_ERROR, payload: message };
};

export const editAllBalances = (targetBalance: number) => {
  return { type: EDIT_ALL_BALANCES, payload: { targetBalance } };
};

export const editAllBalancesSuccess = () => {
  return { type: EDIT_ALL_BALANCES_SUCCESS };
};

export const editAllBalancesError = (message: string) => {
  return { type: EDIT_ALL_BALANCES_ERROR, payload: message };
};

export const clearEditBalanceEffects = () => {
  return { type: CLEAR_EDIT_BALANCE_EFFECTS };
};
