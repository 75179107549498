import { History } from 'history';
import { CurrentUserState } from './model';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_ERROR,
  LOGOUT_USER,
  ENABLE_FORCE_LOGOUT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CLEAR_AUTH_ERROR,
  CLEAR_CHANGE_PASSWORD_ERROR,
} from '../actions';

export const loginUser = (
  user: { email: string; password: string },
  history: History
) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const loginUserSuccess = (user: CurrentUserState) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message: string) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const fetchUserDetails = () => ({
  type: FETCH_USER_DETAILS,
});

export const fetchUserDetailsSuccess = (user: CurrentUserState) => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload: user,
});

export const fetchUserDetailsError = (message: string) => ({
  type: FETCH_USER_DETAILS_ERROR,
  payload: { message },
});

export const forgotPassword = (email: string, history: History) => ({
  type: FORGOT_PASSWORD,
  payload: { email, history },
});
export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});
export const forgotPasswordError = (message: string) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = (
  newPassword: string,
  resetToken: string,
  isFromMobile: boolean,
  history: History
) => ({
  type: RESET_PASSWORD,
  payload: { resetToken, newPassword, isFromMobile, history },
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});
export const resetPasswordError = (message: string) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const logoutUser = (
  history: History,
  message: string = '',
  isWithoutRedirection: boolean = false
) => ({
  type: LOGOUT_USER,
  payload: { history, message, isWithoutRedirection },
});

export const enableForceLogout = () => ({
  type: ENABLE_FORCE_LOGOUT,
});

export const clearAuthError = () => ({ type: CLEAR_AUTH_ERROR });

export const changePassword = (
  oldPassword: string,
  newPassword: string,
  history: History
) => ({
  type: CHANGE_PASSWORD,
  payload: { oldPassword, newPassword, history },
});

export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });

export const changePasswordError = (message: string) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { message },
});

export const clearChangePasswordError = () => ({
  type: CLEAR_CHANGE_PASSWORD_ERROR,
});
