import { DistributionId, MassDistribution } from './model';

import {
  SET_ACTIVE_DETAILS_ID,
  CLEAR_ACTIVE_DETAILS_ID,
  SHOW_STORED_MASS_DISTRIBUTION,
  FETCH_MASS_DISTRIBUTION_SUCCESS,
  FETCH_MASS_DISTRIBUTION_ERROR,
} from '../actions';

export const setActiveDetailsId = (distributionId: DistributionId) => {
  return { type: SET_ACTIVE_DETAILS_ID, payload: distributionId };
};

export const clearActiveDetailsId = () => {
  return { type: CLEAR_ACTIVE_DETAILS_ID };
};

export const showStoredMassDistribution = () => {
  return { type: SHOW_STORED_MASS_DISTRIBUTION };
};

export const fetchMassDistributionSuccess = (results: MassDistribution) => {
  return {
    type: FETCH_MASS_DISTRIBUTION_SUCCESS,
    payload: results,
  };
};

export const fetchMassDistributionError = (message: string) => {
  return { type: FETCH_MASS_DISTRIBUTION_ERROR, payload: message };
};
