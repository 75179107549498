import moment from 'moment';

import {
  SET_DISTRIBUTION_FILTER,
  FETCH_DISTRIBUTION_LISTING,
  FETCH_DISTRIBUTION_LISTING_SUCCESS,
  FETCH_DISTRIBUTION_LISTING_ERROR,
} from '../actions';

import {
  DistributionFilterState,
  DistributionState,
  DistributionFilterAction,
  DistributionAction,
} from './model';

const INIT_DISTRIBUTION_FILTER_STATE:DistributionFilterState = {
  currentPage: 1,
  query: '',
  startDate: moment().subtract(1, 'months').format('DD/MM/YYYY'),
  endDate: moment().format('DD/MM/YYYY'),
  sortBy: 'distributionDate',
  sortOrder: 'DESC',
};

export const distributionFilterReducer = (
  state: DistributionFilterState = INIT_DISTRIBUTION_FILTER_STATE,
  action: DistributionFilterAction
) => {
  switch (action.type) {
    case SET_DISTRIBUTION_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_DISTRIBUTION_STATE:DistributionState = {
  results: null,
  loading: false,
  error: '',
};

export const distributionReducer = (
  state: DistributionState = INIT_DISTRIBUTION_STATE,
  action: DistributionAction
) => {
  switch (action.type) {
    case FETCH_DISTRIBUTION_LISTING:
      return { ...state, loading: true, error: '' };
    case FETCH_DISTRIBUTION_LISTING_SUCCESS:
      return { loading: false, error: '', results: { ...action.payload } };
    case FETCH_DISTRIBUTION_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
