export interface NavLinkType {
  id: string;
  text: string;
  to: string;
  icon?: string;
  roles?: number[];
  newWindow?: boolean;
}

export const loginRoute = '/user/login';
export const userSuccessRoute = '/user/success'
export const appRoute = '/app';
export const dashboardRoute = appRoute + '/dashboard';
export const profileRoute = appRoute + '/profile';
export const createAccountRoute = appRoute + '/accounts/new';
export const manageAccountsRoute = appRoute + '/manage-accounts';
export const appSuccessRoute = appRoute + '/success';
export const appErrorRoute = appRoute + '/error';
export const unauthorizedRoute = '/unauthorized';
export const systemConfigRoute = appRoute + '/system-config';

const linksData: NavLinkType[] = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    icon: 'icon icon-bar-chart-4',
    to: dashboardRoute,
  },
  {
    id: 'transactions',
    text: 'Transactions',
    icon: 'icon icon-dollar',
    to: `${appRoute}/transactions`,
  },
  {
    id: 'balances',
    text: 'Balance Distribution',
    icon: 'icon icon-credit-card',
    to: `${appRoute}/balance-distribution`,
    // roles: [UserRole.Admin, UserRole.Editor],
  },
  {
    id: 'distributions',
    text: 'Distribution History',
    icon: 'icon icon-clock-back',
    to: `${appRoute}/distribution-history`,
  },
  {
    id: 'accounts',
    text: 'Manage Accounts',
    icon: 'icon icon-conference',
    to: manageAccountsRoute,
  },
  {
    id: 'system-config',
    text: 'System Config',
    icon: 'icon icon-Settings-Window',
    to: systemConfigRoute,
  },
];

export const mapSecondaryToPrimary = [
  {
    secondaryRegExp: new RegExp(`\\${appRoute}\\/accounts\\/.*\\/edit`, 'i'),
    primaryRoute: manageAccountsRoute,
  },
  {
    secondaryPath: profileRoute,
    primaryRoute: dashboardRoute,
  },
  {
    secondaryPath: createAccountRoute,
    primaryRoute: manageAccountsRoute,
  },
];

export const settingsLinksData: NavLinkType[] = [
  {
    id: 'profile',
    text: 'Profile',
    to: `${appRoute}/profile`,
  },
];

export default linksData;
