import React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

interface SpinnerLoaderProps {
  classNames?: string;
  sizeClassName?: 'sm' | 'md' | 'lg' | 'xl';
  transitionIn?: boolean;
  onTransitionExited?: () => void;
  progressRef?: React.RefObject<HTMLDivElement>;
}
const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({
  classNames,
  sizeClassName = 'md',
  transitionIn = true,
  onTransitionExited = () => {},
  progressRef,
}) => {
  return (
    <CSSTransition
      classNames="react-transition-group--fade-in-out"
      in={transitionIn}
      appear
      unmountOnExit
      onExited={onTransitionExited}
      timeout={800}
    >
      <div
        className={cx('spinner-loader', sizeClassName, classNames, {
          'has-progress': !!progressRef,
        })}
      >
        <div className="spinner-loader__icon">
          <i className="icon icon-spinner-tail" />
          {progressRef && (
            <div className="spinner-loader__progress" ref={progressRef}></div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default SpinnerLoader;
