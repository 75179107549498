import {
  SET_ACTIVE_DETAILS_ID,
  CLEAR_ACTIVE_DETAILS_ID,
  SHOW_STORED_MASS_DISTRIBUTION,
  FETCH_MASS_DISTRIBUTION_SUCCESS,
  FETCH_MASS_DISTRIBUTION_ERROR,
} from '../actions';

import { MassDistributionState, MassDistributionAction } from './model';

const INIT_MASS_DISTRIBUTION_STATE:MassDistributionState = {
  showDetailsId: '',
  results: [],
  loading: false,
  error: '',
};

const massDistributionReducer = (
  state: MassDistributionState = INIT_MASS_DISTRIBUTION_STATE,
  action: MassDistributionAction
) => {
  switch (action.type) {
    case SET_ACTIVE_DETAILS_ID:
      return {
        ...state,
        showDetailsId: action.payload,
        loading: true,
        error: '',
      };
    case CLEAR_ACTIVE_DETAILS_ID:
      return {
        ...state,
        showDetailsId: '',
        loading: false,
        error: '',
      };
    case SHOW_STORED_MASS_DISTRIBUTION:
      return { ...state, loading: false, error: '' };
    case FETCH_MASS_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        results: [...state.results, action.payload],
      };

    case FETCH_MASS_DISTRIBUTION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default massDistributionReducer;
