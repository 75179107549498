import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from 'redux-saga/effects';

import { FETCH_TRANSACTION_LISTING } from '../actions';

import {
  fetchTransactionListingSuccess,
  fetchTransactionListingError,
} from './actions';

import { fetchTransactionsAPI } from '../../apiCalls';

import messageStrings from '../../helpers/messageStrings'
import { TransactionFilterState, TransactionListingResponse } from './model';

const fetchTransactionsAsync = async (
  filters: TransactionFilterState
): Promise<TransactionListingResponse> => {
  try {
    const response = await fetchTransactionsAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchTransactions({
  payload,
}: {
  type: typeof FETCH_TRANSACTION_LISTING;
  payload: TransactionFilterState;
}) {
  try {
    const results: SagaReturnType<typeof fetchTransactionsAsync> = yield call(
      fetchTransactionsAsync,
      payload
    );

    yield put(fetchTransactionListingSuccess(results));

  } catch (error) {
    yield put(
      fetchTransactionListingError(error.message || messageStrings.defaultError)
    );
  }
}
export function* watchFetchTransactionListing() {
  yield takeEvery(FETCH_TRANSACTION_LISTING, handleFetchTransactions);
}

export default function* rootSaga() {
  yield all([fork(watchFetchTransactionListing)]);
}
