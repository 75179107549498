import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_ERROR,
  LOGOUT_USER,
  ENABLE_FORCE_LOGOUT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CLEAR_AUTH_ERROR,
  CLEAR_CHANGE_PASSWORD_ERROR,
} from '../actions';

import {
  AuthState,
  AuthAction,
  ChangePasswordState,
  ChangePasswordAction,
} from './model';
import { isAuthGuardActive, currentUser } from '../../helpers/defaultValues';

const INIT_STATE:AuthState = {
  currentUser: isAuthGuardActive ? null : currentUser,
  loading: false,
  error: '',
  isForceLogoutOn: false,
};

export const authReducer = (
  state: AuthState = INIT_STATE,
  action: AuthAction
) => {
  switch (action.type) {
    case LOGIN_USER:
    case FETCH_USER_DETAILS:
      return { ...state, loading: true, error: '' };

    case LOGIN_USER_SUCCESS:
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };

    case LOGIN_USER_ERROR:
    case FETCH_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };

    case FORGOT_PASSWORD:
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };

    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case FORGOT_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case ENABLE_FORCE_LOGOUT:
      return { ...state, isForceLogoutOn: true };

    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        error: action.payload.message,
        isForceLogoutOn: false,
      };

    case CLEAR_AUTH_ERROR:
      return { ...state, error: '' };

    default:
      return state;
  }
};

// Splitted changePassword state from auth state as changing auth state during change password flow
// will cause authHelper's ProtectedRoute to recreate and remount page component, since ProtectedRoute
// is connected to auth state
const INIT_CHANGE_PASSWORD_STATE:ChangePasswordState = {
  loading: false,
  error: '',
};

export const changePasswordReducer = (
  state: ChangePasswordState = INIT_CHANGE_PASSWORD_STATE,
  action: ChangePasswordAction
) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { loading: true, error: '' };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        loading: false,
        error: '',
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_CHANGE_PASSWORD_ERROR:
      return { ...state, error: '' };

    default:
      return state;
  }
};
