/* eslint-disable no-param-reassign */
import React from 'react';
import cx from 'classnames';

const MIN_LENGTH = 8;
const REQUIRE_LOWERCASE = true;
const REQUIRE_UPPERCASE = true;
const REQUIRE_NUMBER = true;
const REQUIRE_SYMBOL = true;

interface PasswordCheckerProps {
  newPassword: string;
}

export const validatePasswordRequirements = (newPassword: string) => {
  const characterTests: {
    condition: string;
    regexp: RegExp;
    isRequired: boolean;
    hasPassed: undefined | boolean;
  }[] = [
    {
      condition: 'At least 1 lowercase',
      regexp: /[a-z]+/,
      isRequired: REQUIRE_LOWERCASE,
      hasPassed: undefined,
    },
    {
      condition: 'At least 1 uppercase',
      regexp: /[A-Z]+/,
      isRequired: REQUIRE_UPPERCASE,
      hasPassed: undefined,
    },
    {
      condition: 'At least 1 number',
      regexp: /[0-9]+/,
      isRequired: REQUIRE_NUMBER,
      hasPassed: undefined,
    },
    {
      condition: 'At least 1 symbol',
      // eslint-disable-next-line no-useless-escape
      regexp: /[!@#\$%\^&\*\.\(\)\_\-]+/,
      isRequired: REQUIRE_SYMBOL,
      hasPassed: undefined,
    },
  ];

  // Test for minimum characters ===============================================
  const hasPassedMinLength = newPassword.length >= MIN_LENGTH;

  // Test for other conditions =================================================
  let hasPassedAll = hasPassedMinLength;
  let numPassedConditions = 0;
  let numTotalConditions = 0;

  characterTests.forEach((test) => {
    if (test.isRequired) {
      if (test.regexp.test(newPassword)) {
        // Passed this condition
        test.hasPassed = true;
        numPassedConditions++;
      } else {
        // Failed
        hasPassedAll = false;
        test.hasPassed = false;
      }
      numTotalConditions++;
    }
  });

  return {
    hasPassedAll,
    characterTests,
    hasPassedMinLength,
    numPassedConditions,
    numTotalConditions,
  };
};

const PasswordChecker: React.FC<PasswordCheckerProps> = ({ newPassword }) => {
  const renderResults = () => {
    const { characterTests, numTotalConditions, hasPassedMinLength } =
      validatePasswordRequirements(newPassword);

    return (
      <>
        <div className="password-checker__min-test">
          <h5 className="password-checker__header">
            Minimum {MIN_LENGTH} characters
          </h5>
          <p
            className={cx(
              'password-checker__result',
              hasPassedMinLength ? 'is-pass' : 'is-fail'
            )}
          >
            <i className="icon icon-circle-tick" />
            <span>
              Entered password length - {newPassword.length} characters
            </span>
          </p>
        </div>
        <h5 className="password-checker__header mt-3">
          Comply to these {numTotalConditions} categories:
        </h5>
        <ul className="password-checker__character-tests row">
          {characterTests
            .filter((test) => test.isRequired)
            .map((test) => (
              <li
                className="password-checker__test-item col-12 col-sm-6 mb-1"
                key={test.condition}
              >
                <p
                  className={cx(
                    'password-checker__result',
                    test.hasPassed ? 'is-pass' : 'is-fail'
                  )}
                >
                  <i className="icon icon-circle-tick" />
                  <span>{test.condition}</span>
                </p>
              </li>
            ))}
        </ul>
      </>
    );
  };

  if (!newPassword) {
    return <></>;
  }

  return <div className="password-checker mt-3">{renderResults()}</div>;
};

export default PasswordChecker;
