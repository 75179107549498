import { DistributionFilter, DistributionListing } from './model';

import {
  SET_DISTRIBUTION_FILTER,
  FETCH_DISTRIBUTION_LISTING,
  FETCH_DISTRIBUTION_LISTING_SUCCESS,
  FETCH_DISTRIBUTION_LISTING_ERROR,
} from '../actions';

export const setDistributionFilter = (
  newFiltersKeyValues: DistributionFilter
) => {
  return { type: SET_DISTRIBUTION_FILTER, payload: newFiltersKeyValues };
};

export const fetchDistributionListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_DISTRIBUTION_LISTING, payload: filters };
};

export const fetchDistributionListingSuccess = (
  results: DistributionListing
) => {
  return { type: FETCH_DISTRIBUTION_LISTING_SUCCESS, payload: results };
};

export const fetchDistributionListingError = (message: string) => {
  return { type: FETCH_DISTRIBUTION_LISTING_ERROR, payload: message };
};
