import { DashboardAnalysedData } from './model';

import {
  FETCH_DASHBOARD_DATA_ANALYTICS,
  FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS,
  FETCH_DASHBOARD_DATA_ANALYTICS_ERROR,
} from '../actions';

export const fetchDashboardDataAnalytics = () => {
  return { type: FETCH_DASHBOARD_DATA_ANALYTICS };
};

export const fetchDashboardDataAnalyticsSuccess = (
  responseData: DashboardAnalysedData
) => {
  return {
    type: FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS,
    payload: responseData,
  };
};

export const fetchDashboardDataAnalyticsError = (message: string) => {
  return {
    type: FETCH_DASHBOARD_DATA_ANALYTICS_ERROR,
    payload: message,
  };
};
