/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';

import SpinnerLoader from '../components/SpinnerLoader/SpinnerLoader';
import { logoutUser, fetchUserDetails } from '../redux/auth/actions';
import { isAuthGuardActive } from './defaultValues';
import { loginRoute } from './navLinks';
import { hasValidJWTToken } from './utils';

import { RootState } from '../redux/reducers';

export enum UserRole {
  Admin = 0,
  Beneficiary = 1,
  Merchant = 2,
}

interface ProtectedRouteProps {
  path?: string;
  component: React.ComponentType;
  roles?: UserRole[] | undefined;
}

export const ProtectedRoute = ({
  component,
  roles = undefined,
  ...rest
}: ProtectedRouteProps) => {
  const OriginalComponent = component;

  const ProtectedComponent: React.FC<any> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const auth = useSelector((state: RootState) => state.authUser);
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
    const [hasDispatchedLogout, setHasDispatchedLogout] =
      useState<boolean>(false);

    const { isJWTTokenValid, jwtTokenError } = hasValidJWTToken();
    const hasNoUserDetails = !auth.currentUser || !auth.currentUser.id;

    useEffect(() => {
      // eslint-disable-line react-hooks/exhaustive-deps
      if (!isAuthGuardActive) {
        return;
      }
      // No JWT token or JWT token has expired
      // Kick user out to login page
      if (!isJWTTokenValid) {
        if (!hasDispatchedLogout) {
          // Dispatch only once, prevent infinite logout loops
          dispatch(logoutUser(history, jwtTokenError));
          setHasDispatchedLogout(true);
        }
        return;
      }

      // No user details, need to fetch from server first before proceeding
      if (hasNoUserDetails) {
        if (!auth.loading) {
          dispatch(fetchUserDetails());
        } else if (!isShowSpinner) {
          setIsShowSpinner(true);
        }
        return;
      }
    });

    if (!isAuthGuardActive) {
      // Auth guard not active, no extra checks required
      return <OriginalComponent {...props} />;
    }

    // Auth guard active ------------------------------------------------------

    if (!isJWTTokenValid) {
      return (
        <Redirect
          to={{
            pathname: loginRoute,
            state: { from: props.location },
          }}
        />
      );
    }
    // Valid JWT token not expired yet ----------------------------------------

    if (hasNoUserDetails || isShowSpinner) {
      // Let spinner finish exit animation before proceeding with intended View component
      const onSpinnerTransitionExited = () => {
        setIsShowSpinner(false);
      };

      return (
        <SpinnerLoader
          sizeClassName="lg"
          transitionIn={auth.loading}
          onTransitionExited={onSpinnerTransitionExited}
        />
      );
    }

    // Has user details -------------------------------------------------------
    if (!roles) {
      // Route does not require role authorization
      return <OriginalComponent {...props} />;
    }

    // Route requires role authorization --------------------------------------
    if (
      !auth.currentUser ||
      !auth.currentUser.role ||
      !roles.includes(auth.currentUser.role)
    ) {
      // Unauthorized
      // Redirect to error page without logging out
      return (
        <Redirect
          to={{
            pathname: '/unauthorized',
            state: { from: props.location },
          }}
        />
      );
    }

    // Has required role ------------------------------------------------------

    // Has authGuardActive
    // Has not expired JWT
    // Has user details
    // Requires role authorization
    // Has required role
    return <OriginalComponent {...props} />;
  };

  return <Route {...rest} component={ProtectedComponent} />;
};
