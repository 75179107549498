/* eslint-disable import/no-cycle */

/* AUTH */
export const LOGIN_USER: string = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS: string = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR: string = 'LOGIN_USER_ERROR';
export const FETCH_USER_DETAILS: string = 'FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_SUCCESS: string = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_ERROR: string = 'FETCH_USER_DETAILS_ERROR';
export const LOGOUT_USER: string = 'LOGOUT_USER';
export const ENABLE_FORCE_LOGOUT: string = 'ENABLE_FORCE_LOGOUT';
export const FORGOT_PASSWORD: string = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS: string = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR: string = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD: string = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS: string = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR: string = 'RESET_PASSWORD_ERROR';
export const CLEAR_AUTH_ERROR: string = 'CLEAR_AUTH_ERROR';
export const CHANGE_PASSWORD: string = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS: string = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR: string = 'CHANGE_PASSWORD_ERROR';
export const CLEAR_CHANGE_PASSWORD_ERROR: string =
  'CLEAR_CHANGE_PASSWORD_ERROR';

export * from './auth/actions';

/* SIDEBAR */
export const SET_SIDEBAR_OPEN: string = 'SET_SIDEBAR_OPEN';

/* DASHBOARD */
export const FETCH_DASHBOARD_DATA_ANALYTICS: string =
  'FETCH_DASHBOARD_DATA_ANALYTICS';
export const FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS: string =
  'FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS';
export const FETCH_DASHBOARD_DATA_ANALYTICS_ERROR: string =
  'FETCH_DASHBOARD_DATA_ANALYTICS_ERROR';

/* TRANSACTION */
export const SET_TRANSACTION_FILTER: string = 'SET_TRANSACTION_FILTER';
export const FETCH_TRANSACTION_LISTING: string = 'FETCH_TRANSACTION_LISTING';
export const FETCH_TRANSACTION_LISTING_SUCCESS: string =
  'FETCH_TRANSACTION_LISTING_SUCCESS';
export const FETCH_TRANSACTION_LISTING_ERROR: string =
  'FETCH_TRANSACTION_LISTING_ERROR';

/* BENEFICIARY */
export const SET_BENEFICIARY_FILTER: string = 'SET_BENEFICIARY_FILTER';
export const FETCH_BENEFICIARY_LISTING: string = 'FETCH_BENEFICIARY_LISTING';
export const FETCH_BENEFICIARY_LISTING_SUCCESS: string =
  'FETCH_BENEFICIARY_LISTING_SUCCESS';
export const FETCH_BENEFICIARY_LISTING_ERROR: string =
  'FETCH_BENEFICIARY_LISTING_ERROR';
export const UPDATE_BENEFICIARY_CHECKBOXES: string =
  'UPDATE_BENEFICIARY_CHECKBOXES';
export const FETCH_BENEFICIARY_COUNT: string = 'FETCH_BENEFICIARY_COUNT';
export const FETCH_BENEFICIARY_COUNT_SUCCESS: string =
  'FETCH_BENEFICIARY_COUNT_SUCCESS';
export const FETCH_BENEFICIARY_COUNT_ERROR: string =
  'FETCH_BENEFICIARY_COUNT_ERROR';

export const EDIT_SELECTED_BALANCES: string = 'EDIT_SELECTED_BALANCES';
export const EDIT_SELECTED_BALANCES_SUCCESS: string =
  'EDIT_SELECTED_BALANCES_SUCCESS';
export const EDIT_SELECTED_BALANCES_ERROR: string =
  'EDIT_SELECTED_BALANCES_ERROR';
export const EDIT_ALL_BALANCES: string = 'EDIT_ALL_BALANCES';
export const EDIT_ALL_BALANCES_SUCCESS: string = 'EDIT_ALL_BALANCES_SUCCESS';
export const EDIT_ALL_BALANCES_ERROR: string = 'EDIT_ALL_BALANCES_ERROR';
export const CLEAR_EDIT_BALANCE_EFFECTS: string = 'CLEAR_EDIT_BALANCE_EFFECTS';

/* DISTRIBUTION */
export const SET_DISTRIBUTION_FILTER: string = 'SET_DISTRIBUTION_FILTER';
export const FETCH_DISTRIBUTION_LISTING: string = 'FETCH_DISTRIBUTION_LISTING';
export const FETCH_DISTRIBUTION_LISTING_SUCCESS: string =
  'FETCH_DISTRIBUTION_LISTING_SUCCESS';
export const FETCH_DISTRIBUTION_LISTING_ERROR: string =
  'FETCH_DISTRIBUTION_LISTING_ERROR';

/* MASS DISTRIBUTION */
export const SET_ACTIVE_DETAILS_ID: string = 'SET_ACTIVE_DETAILS_ID';
export const CLEAR_ACTIVE_DETAILS_ID: string = 'CLEAR_ACTIVE_DETAILS_ID';
export const SHOW_STORED_MASS_DISTRIBUTION: string =
  'SHOW_STORED_MASS_DISTRIBUTION';
export const FETCH_MASS_DISTRIBUTION_SUCCESS: string =
  'FETCH_MASS_DISTRIBUTION_SUCCESS';
export const FETCH_MASS_DISTRIBUTION_ERROR: string =
  'FETCH_MASS_DISTRIBUTION_ERROR';

/* ACCOUNT */
export const SET_ACCOUNT_FILTER: string = 'SET_ACCOUNT_FILTER';
export const FETCH_ACCOUNT_LISTING: string = 'FETCH_ACCOUNT_LISTING';
export const FETCH_ACCOUNT_LISTING_SUCCESS: string =
  'FETCH_ACCOUNT_LISTING_SUCCESS';
export const FETCH_ACCOUNT_LISTING_ERROR: string =
  'FETCH_ACCOUNT_LISTING_ERROR';
export const REFETCH_ACCOUNT_LISTING: string = 'REFETCH_ACCOUNT_LISTING';

/* CREATE ACCOUNT */
export const SET_CREATE_ACCOUNT_ROLE: string = 'SET_CREATE_ACCOUNT_ROLE';

/* COMPANY NAMES */
export const FETCH_COMPANY_NAMES: string = 'FETCH_COMPANY_NAMES';
export const FETCH_COMPANY_NAMES_SUCCESS: string =
  'FETCH_COMPANY_NAMES_SUCCESS';
export const FETCH_COMPANY_NAMES_ERROR: string = 'FETCH_COMPANY_NAMES_ERROR';
