import {
  SET_ACCOUNT_FILTER,
  FETCH_ACCOUNT_LISTING,
  FETCH_ACCOUNT_LISTING_SUCCESS,
  FETCH_ACCOUNT_LISTING_ERROR,
  SET_CREATE_ACCOUNT_ROLE,
  FETCH_COMPANY_NAMES,
  FETCH_COMPANY_NAMES_SUCCESS,
  FETCH_COMPANY_NAMES_ERROR,
} from '../actions';

import {
  AccountFilterState,
  AccountState,
  AccountFilterAction,
  AccountAction,
  CreateAccountState,
  CreateAccountAction,
  CompanyNamesState,
  CompanyNamesAction,
} from './model';

const INIT_ACCOUNT_FILTER_STATE:AccountFilterState = {
  currentPage: 1,
  query: '',
  role: 'beneficiary',
  status: '',
  sortBy: 'firstName',
  sortOrder: 'ASC',
};

export const accountFilterReducer = (
  state: AccountFilterState = INIT_ACCOUNT_FILTER_STATE,
  action: AccountFilterAction
) => {
  switch (action.type) {
    case SET_ACCOUNT_FILTER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const INIT_ACCOUNT_STATE:AccountState = {
  results: null,
  loading: false,
  error: '',
};

export const accountReducer = (
  state: AccountState = INIT_ACCOUNT_STATE,
  action: AccountAction
) => {
  switch (action.type) {
    case FETCH_ACCOUNT_LISTING:
      return { ...state, loading: true, error: '' };
    case FETCH_ACCOUNT_LISTING_SUCCESS:
      return { loading: false, error: '', results: { ...action.payload } };
    case FETCH_ACCOUNT_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const INIT_CREATE_ACCOUNT_STATE: CreateAccountState = {
  role: 'beneficiary',
};

export const createAccountReducer = (
  state: CreateAccountState = INIT_CREATE_ACCOUNT_STATE,
  action: CreateAccountAction
) => {
  switch (action.type) {
    case SET_CREATE_ACCOUNT_ROLE:
      return { role: action.payload.role };
    default:
      return state;
  }
};

const INIT_COMPANY_NAMES_STATE: CompanyNamesState = {
  data: null,
  loading: false,
  error: '',
};

export const companyNamesReducer = (
  state: CompanyNamesState = INIT_COMPANY_NAMES_STATE,
  action: CompanyNamesAction
) => {
  switch (action.type) {
    case FETCH_COMPANY_NAMES:
      return { ...state, loading: true, error: '' };
    case FETCH_COMPANY_NAMES_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case FETCH_COMPANY_NAMES_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    default:
      return state;
  }
};
