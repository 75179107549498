import { capitalizeFirstChar } from './utils';

export default {
  networkError: 'Encountered network error. Please check your internet connection.',
  relogin: 'Session has ended, please login again',
  login: 'Please login to the application',
  newPasswordFail: 'Please fill in a valid new password',
  noConfirmPassword: 'Please confirm your new password',
  forgotPasswordSuccess: 'Instructions to reset your password have been sent to the email provided',
  confirmNotMatchNewPassword: 'Confirm password does not match new password',
  noOldPassword: 'Please fill in your old password',
  oldSameAsNewPassword: 'New password should not be the same as old password',
  changePasswordSuccess: 'Password has been successfully changed. Please relogin with your new password.',
  useResetLinkFromEmail: 'Please use the reset link from the email sent to you',
  mobilePasswordResetSuccess:
    'Password has been successfully reset. You may now close this browser and login to the mobile application using your new password.',
  webPasswordResetSuccess:
    'Password has been successfully reset. Please log in with your new password.',
  createAccountSuccess: (role: string) =>
    `${capitalizeFirstChar(role)} account has been successfully created`,
  createAccountError:
    'Something went wrong, account was not created. Please try again later',
  editAccountSuccess: (role: string, email: string) =>
    `${capitalizeFirstChar(
      role
    )} account for ${email} has been successfully updated`,
  editAccountError:
    'Something went wrong, account was not updated. Please try again later',
  noCompanyNames:
    'Unable to retrieve list of companies, therefore this service is not available. Please try again later.',
  noCompanyCreated:
    'There are no companies created in the system, therefore account creation service is not available. Please contact the system administrators.',
  requiredInput: 'Please fill in this field',
  requiredOption: 'Please choose an option',
  requiredEmail: 'Please enter an email address',
  invalidEmail: 'Invalid email address',
  invalidDateFormat:
    'Invalid date format, expected DD/MM/YYYY format (e.g. 31/12/2030)',
  accountRetrievalFail:
    'Failed to retrieve existing account information, account cannot be edited.',
  invalidAccountRoleEdit: 'Accounts with this role cannot be edited.',
  editNoAccountSelected:
    'No account was selected to be edited. Please edit an account from Manage Accounts page.',
  noCSVFile: 'Please select a CSV file',
  invalidCSVFileType:
    'File chosen is not a CSV file. If you are using an Excel file, please export file in CSV format instead.',
  passwordResetMerchantRelogin:
    'Upon successful password reset, Merchant will be immediately logged out of mobile app, and will have to use this new password set by you to relogin.',
  invalidAccountStatus:
    'Invalid account status. Status should be "active" or "deactivated".',
  invalidAccountRole:
    'Invalid account role. Role should be "beneficiary", "merchant" or "admin".',
  requireRFID: 'RFID is missing',
  missingRequiredAccountDetail:
    'Please check that firstName, lastName, companyName are included',
  missingEmail: 'Email is missing',
  noAccountFromEmail: 'Account could not be found based on email provided',
  companyNotFound:
    'Could not find company, please fill in a companyName or check your companyName spelling.',
  invalidCardExpiryDateFormat:
    'cardExpiryDate format is not valid, expected format is DD/MM/YYYY (e.g. 31/12/2030)',
  loadAPIError:
    'Something went wrong, existing information could not be loaded. Please try again later',
  submitAPIError:
    'Something went wrong, information could not be sent/processed by the server. Please try again later.',
  failToLoadSystemConfig:
    'Could not load existing system configurations, please try again later',
  invalidDistributionAmount:
    'Distribution amount should not be 0. Please enter in an appropriate amount.',
  saveConfigSuccess:
    'Configurations were successfully saved. Any changes will take effect immediately.',
  fetchExportError:
    'Something went wrong, information could not be exported. Please try again later.',
  startDateAfterEndDate: (functionality: string) =>
    `Start Date of ${functionality} cannot be later than End Date`,
  exceedDateRange: (maxPeriod: string, functionality: string) =>
    `For ${functionality}, number of days between Start Date and End Date cannot exceed ${maxPeriod}`,
  loadingExport:
    'Please wait... File download will start automatically once ready.',
  exportSuccess: 'File download should start right now',
  maxDistributionAmount: (maxDistributionAmount: string | number) =>
    `Distribution amount should not exceed $` + maxDistributionAmount,
  defaultError: 'Something went wrong, please try again later.'
};
