import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  SagaReturnType,
} from 'redux-saga/effects';

import { SET_ACTIVE_DETAILS_ID } from '../actions';

import {
  showStoredMassDistribution,
  fetchMassDistributionSuccess,
  fetchMassDistributionError,
} from './actions';

import {
  MassDistribution,
  DistributionId,
  MassDistributionResponse,
} from './model';

import { RootState } from '../reducers';

import { fetchMassDistributionAPI } from '../../apiCalls';
import messageStrings from '../../helpers/messageStrings'

const fetchMassDistributionAsync = async (
  distributionId: DistributionId
): Promise<MassDistributionResponse> => {
  try {
    const response = await fetchMassDistributionAPI(distributionId);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function getStoredMassDistributions(state: RootState): MassDistribution[] {
  return state.massDistribution.results;
}

function* showStoredOrFetchMassDistribution({
  payload,
}: {
  type: typeof SET_ACTIVE_DETAILS_ID;
  payload: DistributionId;
}) {
  const storedMassDistributions: SagaReturnType<
    typeof getStoredMassDistributions
  > = yield select(getStoredMassDistributions);

  const isStored = storedMassDistributions.some(
    (massDistribution) => massDistribution.distributionId === payload
  );

  if (isStored) {
    // No need to fetch from server, use from store
    yield put(showStoredMassDistribution());
  } else {
    // Fetch from server and store
    try {
      const results: SagaReturnType<typeof fetchMassDistributionAsync> =
        yield call(fetchMassDistributionAsync, payload);

        yield put(fetchMassDistributionSuccess(results));

    } catch (error) {
      yield put(fetchMassDistributionError(error.message || messageStrings.defaultError));
    }
  }
}

export function* watchSetActiveDetailsId() {
  yield takeEvery(SET_ACTIVE_DETAILS_ID, showStoredOrFetchMassDistribution);
}

export default function* rootSaga() {
  yield all([fork(watchSetActiveDetailsId)]);
}
