import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from 'redux-saga/effects';

import { FETCH_DASHBOARD_DATA_ANALYTICS } from '../actions';

import {
  fetchDashboardDataAnalyticsError,
  fetchDashboardDataAnalyticsSuccess,
} from './actions';

import { fetchDashboardDataAnalyticsAPI } from '../../apiCalls';

import { DashboardDataAnalyticsResponse } from './model';
import messageStrings from '../../helpers/messageStrings'

const fetchDashboardDataAnalyticsAsync =
  async (): Promise<DashboardDataAnalyticsResponse> => {
    try {
      const response = await fetchDashboardDataAnalyticsAPI();
      return response.data;
    } catch (err) {
      throw err;
    }
  };

function* handleFetchDashboardDataAnalytics() {
  try {
    const response: SagaReturnType<typeof fetchDashboardDataAnalyticsAsync> =
      yield call(fetchDashboardDataAnalyticsAsync);

      yield put(fetchDashboardDataAnalyticsSuccess(response));
    
  } catch (error) {
    yield put(fetchDashboardDataAnalyticsError(messageStrings.defaultError));
  }
}

export function* watchFetchDashboardDataAnalytics() {
  yield takeEvery(
    FETCH_DASHBOARD_DATA_ANALYTICS,
    handleFetchDashboardDataAnalytics
  );
}

export default function* rootSaga() {
  yield all([fork(watchFetchDashboardDataAnalytics)]);
}
