import { AccountFilter, AccountListing, CompanyNames } from './model';

import {
  SET_ACCOUNT_FILTER,
  FETCH_ACCOUNT_LISTING,
  FETCH_ACCOUNT_LISTING_SUCCESS,
  FETCH_ACCOUNT_LISTING_ERROR,
  REFETCH_ACCOUNT_LISTING,
  SET_CREATE_ACCOUNT_ROLE,
  FETCH_COMPANY_NAMES,
  FETCH_COMPANY_NAMES_SUCCESS,
  FETCH_COMPANY_NAMES_ERROR,
} from '../actions';

export const setAccountFilter = (newFiltersKeyValues: AccountFilter) => {
  return { type: SET_ACCOUNT_FILTER, payload: newFiltersKeyValues };
};

export const fetchAccountListing = (filters: { [key: string]: any }) => {
  return { type: FETCH_ACCOUNT_LISTING, payload: filters };
};

export const fetchAccountListingSuccess = (results: AccountListing) => {
  return { type: FETCH_ACCOUNT_LISTING_SUCCESS, payload: results };
};

export const fetchAccountListingError = (message: string) => {
  return { type: FETCH_ACCOUNT_LISTING_ERROR, payload: message };
};

export const refetchAccountListing = () => {
  return { type: REFETCH_ACCOUNT_LISTING };
};

export const setCreateAccountRole = (role: string) => {
  return { type: SET_CREATE_ACCOUNT_ROLE, payload: { role } };
};

export const fetchCompanyNames = () => {
  return { type: FETCH_COMPANY_NAMES };
};

export const fetchCompanyNamesSuccess = (data: CompanyNames) => {
  return { type: FETCH_COMPANY_NAMES_SUCCESS, payload: data };
};

export const fetchCompanyNamesError = (message: string) => {
  return { type: FETCH_COMPANY_NAMES_ERROR, payload: { message } };
};
