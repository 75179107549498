import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { IS_ENV_PRODUCTION } from '../helpers/defaultValues';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (!IS_ENV_PRODUCTION &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default function configureStore() {
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(sagas, null);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers.ts');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
