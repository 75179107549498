import { combineReducers } from 'redux';
import { authReducer, changePasswordReducer } from './auth/reducer';
import sidebarReducer from './sidebar/reducer';
import dashboardReducer from './dashboard/reducer';
import {
  transactionFilterReducer,
  transactionReducer,
} from './transaction/reducer';
import {
  beneficiaryFilterReducer,
  beneficiaryReducer,
  beneficiaryCheckboxesReducer,
  beneficiaryCountReducer,
  editBalanceReducer,
} from './beneficiary/reducer';
import {
  distributionFilterReducer,
  distributionReducer,
} from './distribution/reducer';
import massDistributionReducer from './massDistribution/reducer';
import {
  accountFilterReducer,
  accountReducer,
  createAccountReducer,
  companyNamesReducer,
} from './account/reducer';

import { AuthState, ChangePasswordState } from './auth/model';
import { SidebarState } from './sidebar/model';
import { DashboardState } from './dashboard/model';
import { TransactionFilterState, TransactionState } from './transaction/model';
import {
  BeneficiaryFilterState,
  BeneficiaryState,
  BeneficiaryCheckboxesState,
  BeneficiaryCountState,
  EditBalanceState,
} from './beneficiary/model';
import {
  DistributionFilterState,
  DistributionState,
} from './distribution/model';
import { MassDistributionState } from './massDistribution/model';
import {
  AccountFilterState,
  AccountState,
  CreateAccountState,
  CompanyNamesState,
} from './account/model';

const reducers = combineReducers({
  authUser: authReducer,
  changePassword: changePasswordReducer,
  sidebar: sidebarReducer,
  dashboard: dashboardReducer,
  transactionFilter: transactionFilterReducer,
  transaction: transactionReducer,
  beneficiaryFilter: beneficiaryFilterReducer,
  beneficiary: beneficiaryReducer,
  beneficiaryCheckboxes: beneficiaryCheckboxesReducer,
  beneficiaryCount: beneficiaryCountReducer,
  editBalance: editBalanceReducer,
  distributionFilter: distributionFilterReducer,
  distribution: distributionReducer,
  massDistribution: massDistributionReducer,
  accountFilter: accountFilterReducer,
  account: accountReducer,
  createAccount: createAccountReducer,
  companyNames: companyNamesReducer,
});

export interface RootState {
  authUser: AuthState;
  changePassword: ChangePasswordState;
  sidebar: SidebarState;
  dashboard: DashboardState;
  transactionFilter: TransactionFilterState;
  transaction: TransactionState;
  beneficiaryFilter: BeneficiaryFilterState;
  beneficiary: BeneficiaryState;
  beneficiaryCheckboxes: BeneficiaryCheckboxesState;
  beneficiaryCount: BeneficiaryCountState;
  editBalance: EditBalanceState;
  distributionFilter: DistributionFilterState;
  distribution: DistributionState;
  massDistribution: MassDistributionState;
  accountFilter: AccountFilterState;
  account: AccountState;
  createAccount: CreateAccountState;
  companyNames: CompanyNamesState;
}

export type RootStateKeys = keyof RootState;

export default reducers;
