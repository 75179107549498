/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import dashboardSagas from './dashboard/saga';
import transactionSagas from './transaction/saga';
import beneficiarySagas from './beneficiary/saga';
import distributionSagas from './distribution/saga';
import massDistributionSagas from './massDistribution/saga';
import accountSagas from './account/saga';

export default function* rootSaga(getState: any) {
  yield all([
    authSagas(),
    dashboardSagas(),
    transactionSagas(),
    beneficiarySagas(),
    distributionSagas(),
    massDistributionSagas(),
    accountSagas(),
  ]);
}
