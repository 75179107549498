// eslint-disable-next-line import/no-cycle
import { UserRole } from './authHelper';

export const ENV: 'prod' | 'stage' | string =
  process.env.REACT_APP_DEPLOYMENT_ENV || '';
export const IS_ENV_PRODUCTION = ENV === 'prod' || ENV === 'stage';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const deviceBreakpoints = {
  mobile: 768,
  desktop: 1024,
};

export const JWT_EXPIRATION_DATE_LOCAL_STORAGE_KEY =
  'angelMerchantExpirationDate';
// dev placeholder
export const currentUser = {
  id: 123,
  firstName: 'dummy',
  lastName: 'user',
  role: UserRole.Admin,
  email: 'dummy.user@gmail.com',
};

// eslint-disable-next-line no-useless-escape
export const emailValidationRegexp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const defaultDirection = 'ltr';
export const isAuthGuardActive = true;

export const RESET_PASSWORD_MOBILE_ORIGIN_IDENTIFIER = 'mobile=yes';

export const MAX_DISTRIBUTION_AMOUNT = 1000;
