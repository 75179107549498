import {
  all,
  call,
  fork,
  select,
  put,
  takeEvery,
  SagaReturnType,
} from 'redux-saga/effects';

import {
  FETCH_BENEFICIARY_LISTING,
  FETCH_BENEFICIARY_COUNT,
  EDIT_SELECTED_BALANCES,
  EDIT_ALL_BALANCES,
} from '../actions';

import {
  fetchBeneficiaryListing,
  fetchBeneficiaryListingSuccess,
  fetchBeneficiaryListingError,
  fetchBeneficiaryCountSuccess,
  fetchBeneficiaryCountError,
  editSelectedBalancesSuccess,
  editSelectedBalancesError,
  editAllBalancesSuccess,
  editAllBalancesError,
} from './actions';

import {
  fetchBeneficiariesAPI,
  fetchBeneficiaryCountAPI,
  editSelectedBalancesAPI,
  editAllBalancesAPI,
} from '../../apiCalls';

import {
  BeneficiaryFilterState,
  BeneficiaryListingResponse,
  BeneficiaryCountResponse,
  EditSelectedBalancesPayload,
  EditAllBalancesPayload,
  EditSelectedBalancesResponse,
  EditAllBalancesResponse,
} from './model';

import { RootState } from '../reducers';
import messageStrings from '../../helpers/messageStrings';

const fetchBeneficiariesAsync = async (
  filters: BeneficiaryFilterState
): Promise<BeneficiaryListingResponse> => {
  try {
    const response = await fetchBeneficiariesAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchBeneficiaries({
  payload,
}: {
  type: typeof FETCH_BENEFICIARY_LISTING;
  payload: BeneficiaryFilterState;
}) {
  try {
    const results: SagaReturnType<typeof fetchBeneficiariesAsync> = yield call(
      fetchBeneficiariesAsync,
      payload
    );

    yield put(fetchBeneficiaryListingSuccess(results));
  } catch (error) {
    yield put(
      fetchBeneficiaryListingError(error.message || messageStrings.defaultError)
    );
  }
}

export function* watchFetchBeneficiaryListing() {
  yield takeEvery(FETCH_BENEFICIARY_LISTING, handleFetchBeneficiaries);
}

const fetchBeneficiaryCountAsync =
  async (): Promise<BeneficiaryCountResponse> => {
    try {
      const response = await fetchBeneficiaryCountAPI();
      return response.data;
    } catch (err) {
      throw err;
    }
  };

function* handleFetchBeneficiaryCount() {
  try {
    const response: SagaReturnType<typeof fetchBeneficiaryCountAsync> =
      yield call(fetchBeneficiaryCountAsync);

    yield put(fetchBeneficiaryCountSuccess(response.count || null));

  } catch (error) {
    yield put(fetchBeneficiaryCountError());
  }
}

export function* watchFetchBeneficiaryCount() {
  yield takeEvery(FETCH_BENEFICIARY_COUNT, handleFetchBeneficiaryCount);
}

const editSelectedBalancesAsync = async (
  payload: EditSelectedBalancesPayload
): Promise<EditSelectedBalancesResponse> => {
  try {
    const response = await editSelectedBalancesAPI(payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleEditSelectedBalances({
  payload,
}: {
  type: typeof EDIT_SELECTED_BALANCES;
  payload: EditSelectedBalancesPayload;
}) {
  try {
    const response: SagaReturnType<typeof editSelectedBalancesAsync> =
      yield call(editSelectedBalancesAsync, payload);

      // Show success modal
      yield put(editSelectedBalancesSuccess());
      // Refetch listing
      const listingFilters: BeneficiaryFilterState = yield select(
        (state: RootState) => state.beneficiaryFilter
      );
      yield put(fetchBeneficiaryListing(listingFilters));

  } catch (error) {
    yield put(editSelectedBalancesError(error.message || messageStrings.defaultError));
  }
}

export function* watchEditSelectedBalances() {
  yield takeEvery(EDIT_SELECTED_BALANCES, handleEditSelectedBalances);
}

const editAllBalancesAsync = async (
  payload: EditAllBalancesPayload
): Promise<EditAllBalancesResponse> => {
  try {
    const response = await editAllBalancesAPI(payload);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleEditAllBalances({
  payload,
}: {
  type: typeof EDIT_ALL_BALANCES;
  payload: EditAllBalancesPayload;
}) {
  try {
    const response: SagaReturnType<typeof editAllBalancesAsync> = yield call(
      editAllBalancesAsync,
      payload
    );
      // Show success modal
      yield put(editAllBalancesSuccess());
      // Refetch listing
      const listingFilters: BeneficiaryFilterState = yield select(
        (state: RootState) => state.beneficiaryFilter
      );
      yield put(fetchBeneficiaryListing(listingFilters));
      
  } catch (error) {
    yield put(editAllBalancesError(error.message || messageStrings.defaultError));
  }
}

export function* watchEditAllBalances() {
  yield takeEvery(EDIT_ALL_BALANCES, handleEditAllBalances);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchBeneficiaryListing),
    fork(watchFetchBeneficiaryCount),
    fork(watchEditSelectedBalances),
    fork(watchEditAllBalances),
  ]);
}
