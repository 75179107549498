import {
  all,
  call,
  fork,
  put,
  takeEvery,
  SagaReturnType,
} from 'redux-saga/effects';

import { FETCH_DISTRIBUTION_LISTING } from '../actions';

import {
  fetchDistributionListingSuccess,
  fetchDistributionListingError,
} from './actions';

import { fetchDistributionsAPI } from '../../apiCalls';
import messageStrings from '../../helpers/messageStrings'

import { DistributionFilterState, DistributionListingResponse } from './model';

const fetchDistributionsAsync = async (
  filters: DistributionFilterState
): Promise<DistributionListingResponse> => {
  try {
    const response = await fetchDistributionsAPI(filters);
    return response.data;
  } catch (err) {
    throw err;
  }
};

function* handleFetchDistributions({
  payload,
}: {
  type: typeof FETCH_DISTRIBUTION_LISTING;
  payload: DistributionFilterState;
}) {
  try {
    const results: SagaReturnType<typeof fetchDistributionsAsync> = yield call(
      fetchDistributionsAsync,
      payload
    );

      yield put(fetchDistributionListingSuccess(results));

  } catch (error) {
    yield put(fetchDistributionListingError(error.message || messageStrings.defaultError));
  }
}

export function* watchFetchDistributionListing() {
  yield takeEvery(FETCH_DISTRIBUTION_LISTING, handleFetchDistributions);
}

export default function* rootSaga() {
  yield all([fork(watchFetchDistributionListing)]);
}
