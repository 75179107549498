import {
  FETCH_DASHBOARD_DATA_ANALYTICS,
  FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS,
  FETCH_DASHBOARD_DATA_ANALYTICS_ERROR,
} from '../actions';

import { DashboardState, DashboardAction } from './model';

const INIT_DASHBOARD_STATE:DashboardState = {
  error: '',
  loading: false,
  data: null,
};

const dashboardReducer = (
  state: DashboardState = INIT_DASHBOARD_STATE,
  action: DashboardAction
) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA_ANALYTICS:
      return { ...state, loading: true, error: '' };
    case FETCH_DASHBOARD_DATA_ANALYTICS_SUCCESS:
      return { data: action.payload, error: '', loading: false };

    case FETCH_DASHBOARD_DATA_ANALYTICS_ERROR:
      return { data: null, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
